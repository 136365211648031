/* eslint-disable react/forbid-prop-types */
import {
  Section, SectionHeader, SegmentButton, SegmentGroup,
} from '@cfacorp/cowponents';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from '../Icon';
import ExportExcelEntireDays from '../ExportExcel/ExportExcelEntireDays';
import DatePickerInput from '../DatePicker/DatePicker';

export const types = ['upcoming', 'past'];

const DashboardHeader = (props) => {
  const {
    getOrders,
    onSwitchTabs,
    getOrdersForSpecificDays,
    onCalendarOrdersType,
    getDataForExcelExtraction,
    orders,
    excelExtractData,
    allowExcelDownload,
    downloadExcel,
    exportExcelOrdersLoading,
    excelExtractionError,
    specificDays,
  } = props;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [ordersType, setOrdersType] = useState('upcoming');
  const [disableSegmentButtons, setDisableSegmentButtons] = useState(false);
  const [datePickerIsShown, setDatePickerIsShown] = useState(false);

  const capitalize = ([first, ...rest]) => (first ? first.toUpperCase() + rest.join('') : '');
  const orientation = window.matchMedia('(max-width: 600px)').matches ? 'vertical' : 'horizontal';
  const excelIcon = datePickerIsShown ? 'download-excel' : 'download-excel-disabled';

  const handleDatesChange = (event) => {
    const { startDate: calendarStartDate, endDate: calendarEndDate } = event;
    setStartDate(calendarStartDate);
    setEndDate(calendarEndDate);
    const formattedStart = moment(calendarStartDate).format('YYYY-MM-DD');
    const formattedEnd = moment(calendarEndDate).format('YYYY-MM-DD');
    const currentDate = moment().format('YYYY-MM-DD');
    if (calendarStartDate && calendarEndDate) {
      getOrdersForSpecificDays(formattedStart, formattedEnd);
      setDatePickerIsShown(true);
    }

    if (formattedStart > currentDate) {
      setOrdersType('upcoming');
      onCalendarOrdersType('upcoming');
      setDisableSegmentButtons(true);
    } else if (formattedEnd < currentDate) {
      setOrdersType('past');
      onCalendarOrdersType('past');
      setDisableSegmentButtons(true);
    } else {
      setDisableSegmentButtons(false);
    }
  };

  const onRefreshHandler = () => {
    getOrders();
    setDisableSegmentButtons(false);
    setStartDate(null);
    setEndDate(null);
    setDatePickerIsShown(false);
  };

  const onChangeHandler = (e) => {
    setOrdersType(e.target.value.toLowerCase());
    onSwitchTabs(e);
  };

  const onCancelDate = () => {
    onRefreshHandler();
    onCalendarOrdersType('upcoming');
    setOrdersType('upcoming');
  };

  // map through orders and call reducer action for getting data for excel extraction. this can't be run if date range is not chosen.
  const onOrdersExtractionHandler = () => orders.map(order => getDataForExcelExtraction(order));

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const { startDate, endDate } = specificDays;
    if (startDate) {
      const startDateObject = moment(startDate, 'YYYY-MM-DD, h:mm:ss a').toDate();
      setStartDate(startDateObject);
    }
    if (endDate) {
      const endDateObject = moment(endDate, 'YYYY-MM-DD, h:mm:ss a').toDate();
      setEndDate(endDateObject);
    }
  }, [specificDays]);

  return (
    <StyledSection>
      <SectionHeader>
        <span data-testid="title">Orders</span>
        <DownloadExcelButton
          disabled={!datePickerIsShown}
          onClick={onOrdersExtractionHandler}
          title={datePickerIsShown ? 'Extract to Excel' : 'Select a date range to Exctract order data to Excel'}
          style={exportExcelOrdersLoading ? { transform: 'rotate(-360deg)', transition: '0.5s' } : {}}
        >
          <Icon width="30" height="30" margin="0" className="excel-icon">
            {exportExcelOrdersLoading ? 'loading' : excelIcon}
          </Icon>
        </DownloadExcelButton>
        <ExportExcelEntireDays
          orders={excelExtractData}
          dashboardOrders={orders}
          allowExcelDownload={allowExcelDownload}
          downloadExcel={downloadExcel}
          excelExtractionError={excelExtractionError}
          specificDays={specificDays}
        />
        <ReloadIconButton onClick={onRefreshHandler} title="Refresh orders">
          <Icon width="30" height="30" margin="0">
            refresh
          </Icon>
        </ReloadIconButton>
      </SectionHeader>
      <StyledControlBox>
        <StyledSegmentGroup
          onChange={onChangeHandler}
          defaultValue={ordersType}
          style={{ padding: '0 0 1em' }}
          className="order-type-buttons"
        >
          {types.map((type) => (
            <StyledSegmentButton
              key={type}
              value={type}
              disabled={disableSegmentButtons}
              className={ordersType === type ? 'selected' : ''}
            >
              {`${capitalize(type)}`}
            </StyledSegmentButton>
          ))}
        </StyledSegmentGroup>
        <StyledDatePicker className={orientation}>
          <DatePickerInput
            startDate={startDate}
            endDate={endDate}
            onDatesChange={handleDatesChange}
            selectRange
          />
          {datePickerIsShown && (
            <Close className="button-clear" onClick={onCancelDate}>
              <Icon>close</Icon>
            </Close>
          )}
        </StyledDatePicker>
      </StyledControlBox>
    </StyledSection>
  );
};

const StyledSection = styled(Section)`
  position: relative;
  padding: 0.5em !important;
  @media(max-width: 663px) {
    padding-top: 0 !important;
    padding-bottom: 4em !important;
  }
`;

export const UnstyledButton = styled('button')`
  border: none;
  outline: none;
  background: none;
  box-shadow: none;
  padding: 0;
  display: inline-block;
  font: inherit;
  color: inherit;
  cursor: pointer;
`;

export const ReloadIconButton = styled(UnstyledButton)`
  position: absolute;
  right: 0;
  opacity: 0.6;
  transition: 0.5s all ease-in-out;
  transform-origin: 12px 12px;
  z-index: 1;
  &:focus {
    transform: rotate(-360deg);
  }
`;

export const DownloadExcelButton = styled(UnstyledButton)`
  position: absolute;
  right: 53px;
  z-index: 1;
  width: 35px;
  height: 35px;
  transform-origin: 16px 13.5px;
  fill: ${(props) => (props.disabled ? props.theme.outline : props.theme.primary)};
  &:hover {
   cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  }
`;

const StyledSegmentGroup = styled(SegmentGroup)`
  padding: 0 !important;
`;

const StyledSegmentButton = styled(SegmentButton)`
  width: 115px;
  margin-right: 0;
  background-color:${(props) => props.theme.secondary};
  color: ${(props) => props.theme.primary};
  &:disabled {
    background-color:  ${(props) => props.theme.disabled};
    border-color:  ${(props) => props.theme.text};
    color:  ${(props) => props.theme.text};
  }
  &.selected {
    background-color: ${(props) => props.theme.primary};
    color:  ${(props) => props.theme.secondary};
  }
`;

const StyledControlBox = styled('div')`
  display: flex;
  justify-content: flex-start;
  position: absolute;
  left: 50%;
  bottom: 35px;
  transform: translate(-50%, 0);
  @media(max-width: 663px) {
    flex-direction: column;
    bottom: 0;
  }

`;

const StyledDatePicker = styled('div')`
    width: 230px;
    height: 38px;
    position: relative;
    & .DateRangePickerInput {
      display: flex;
      align-items: center;
    }
    & input {
      font: ${(props) => props.theme.regularTextFont};
      color: ${(props) => props.theme.text};
      border: 1px solid ${(props) => props.theme.outline};
      height: 40px;
      width: 100%;
      box-sizing: border-box;
      margin: 0px;
      appearance: none;
      padding: 3px 3px;
      text-align: center;
      cursor:pointer;
      &:focus {
        border-bottom: none;
      }
    }
    @media(max-height: 900px) {
      & .DateRangePicker_picker {
        background-color: transparent;
      }
      & .DayPicker_transitionContainer__vertical {
        height: 605px !important;
        margin-bottom: 130px;
      }
    }

    & .react-datepicker-wrapper {
      width: 100%;
    }
`;

export const Close = styled('div')`
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translate(0, -50%);
  width: 15px;
  height: 15px;
  & svg {
    margin: 0;
    width: 100%;
    height: 100%;
    fill: ${(props) => props.theme.outline};
  }
  :hover {
    cursor: pointer;
  }
  @media (max-width: ${(props) => props.theme.phone}) {
    width: 38px;
    height: 38px;
    right: -38px;
  }
`;

DashboardHeader.propTypes = {
  getOrders: PropTypes.func,
  onSwitchTabs: PropTypes.func,
  getOrdersForSpecificDays: PropTypes.func,
  onCalendarOrdersType: PropTypes.func,
  getDataForExcelExtraction: PropTypes.func,
  orders: PropTypes.arrayOf(PropTypes.any),
  excelExtractData: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.func,
  ])),
  allowExcelDownload: PropTypes.bool,
  downloadExcel: PropTypes.func,
  exportExcelOrdersLoading: PropTypes.bool,
  excelExtractionError: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  specificDays: PropTypes.objectOf(PropTypes.string),
};
DashboardHeader.defaultProps = {
  getOrders: () => { },
  onSwitchTabs: () => { },
  getOrdersForSpecificDays: () => { },
  onCalendarOrdersType: () => { },
  getDataForExcelExtraction: () => { },
  orders: [],
  excelExtractData: [],
  allowExcelDownload: false,
  downloadExcel: () => { },
  exportExcelOrdersLoading: false,
  excelExtractionError: false,
  specificDays: {},
};

export default DashboardHeader;
