/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { generateDateSlots } from '../../util/format';

import 'react-datepicker/dist/react-datepicker.css';

const DatePickerInput = props => {
  const {
    input,
    availableDates,
    loading,
    bypassBusinessRules,
    startDate,
    endDate,
    onDatesChange,
    selectRange,
  } = props;

  return selectRange && onDatesChange ? (
    <DatePicker
      name="Select Date"
      monthsShown={2}
      dropdownMode="select"
      selectsRange
      inline={false}
      placeholderText="Select Date Range"
      startDate={startDate}
      endDate={endDate}
      onChange={(dates) => onDatesChange({ startDate: dates[0], endDate: dates[1] })}
    />
) : (
  <DatePicker
    name="Select Date"
    monthsShown={1}
    dropdownMode="select"
    disabled={loading}
    placeholderText={loading ? 'Retrieving Available Dates...' : 'Select Date'}
    selected={input.value}
    onChange={(date) => input?.onChange(date)}
    filterDate={(date) => !generateDateSlots(date, availableDates, bypassBusinessRules)}
  />
  );
};

DatePickerInput.propTypes = {
  input: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object,
  ])),
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  onDatesChange: PropTypes.func,
  availableDates: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  bypassBusinessRules: PropTypes.bool,
  selectRange: PropTypes.bool,
};

DatePickerInput.defaultProps = {
  input: {},
  loading: true,
  bypassBusinessRules: false,
  selectRange: false,
  availableDates: [],
  startDate: null,
  endDate: null,
  onDatesChange: null,
};

export default DatePickerInput;
